<template>
  <div>
    <div
      class="emb-card-content px-2 py-2"
      v-if="getUserNotifications.length != 0"
    >
      <v-list two-line>
        <v-list-item
          v-for="(notification, index) in getUserNotifications"
          :key="index"
          :class="index % 2 == 0 ? '' : 'grey lighten-3'"
        >
          <v-list-item-avatar>
            <v-icon large>{{
              $getNotificationIcon(notification)
            }}</v-icon></v-list-item-avatar
          >
          <v-list-item-content>
            <div>{{ $getNotificationMessage(notification) }}</div>
            <div class="grey--text">{{ $formatDate(notification.date) }}</div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
    <div class="emb-card-content px-2 py-2" v-else>
      <v-list two-line>
        <v-list-item>
          <!-- <v-list-item-avatar>
            <v-icon large>{{
              $getNotificationIcon(notification)
            }}</v-icon>
          </v-list-item-avatar> -->
          <v-list-item-content class="no-notification pa-3 rounded-md">
            {{
              $t("message.notificationsPage.noNotification")
            }}</v-list-item-content
          >
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>
<style scoped>
.no-notification {
  background-color: #fcecd9;
}
</style>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getUserNotifications", "getUser"]),
  },
  watch: {
    "$store.state.user": function () {
      if (this.getUser != null && this.fetch_notification_on_user) {
        this.$store.dispatch("fetch_user_notifications", this.getUser.uid);
      }
    },
  },
  components: {
    // embSidebarFilters:SidebarFilters,
    // ProductItems
  },
  data() {
    return {
      fetch_notification_on_user: false,
      // searchClient: algoliasearch(
      // 'latency',
      // 	AppConfig.algoliaApiKey
      // )
    };
  },
  mounted() {
    if (this.getUser != null) {
      this.$store.dispatch("fetch_user_notifications", this.getUser.uid);
    } else {
      this.fetch_notification_on_user = true;
    }
  },
};
</script>